<template>
  <div class="content topic">
    <Bread :breads="breads"></Bread>
    <div class="topic-box topic-head" v-loading="loading">
      <Banner :width="486" :height="246" :imageList="banner" v-loading="loading"></Banner>
      <div class="topic-body">
        <div class="topic-name">{{ topic.title }}</div>
        <div class="topic-dept">{{ topic.responsibleOrganization }}</div>
        <div class="topic-desc">
          <div v-html="topic.content" :class="{showBox:!showBox}"></div>
          <a href="javascript:void(0)" class="blue" v-if="showMore" @click="showBox=!showBox"
             style="display: flex;align-items: center;">
            <template v-if="!showBox">
              展开 <img src="@static/img/icon_open.png"/>
            </template>
            <template v-else>
              收起 <img src="@static/img/icon_close.png"/>
            </template>
          </a>
        </div>
      </div>
    </div>

    <div class="topic-box" v-loading="loading" v-if="platformList && platformList.length > 0">
      <div class="rela-organ">
        <div class="topic-title">相关机构</div>
        <div class="topic-list">
          <div class="topic-item" v-for="item in platformList" :key="item.id">
            <a href="javascript:void(0)" @click="gotoOrgan(item.id)">{{ item.platformName }}</a>
          </div>
        </div>
      </div>

      <div class="rela-result" v-loading="loading" v-if="resList && resList.list.length > 0">
        <div class="topic-title">相关成果</div>
        <div class="topic-list">
          <div class="topic-item" v-for="item in resList.list" :key="item.id">
            <a href="javascript:void(0)" @click="gotoRes(item.id, item.catalogType)">{{ item.cname }}</a>
            <span class="topic-date">{{ item.createTime }}</span>
          </div>
        </div>
      </div>
    </div>

    <div class="topic-news-list-title">
      <div class="topic-title">相关资源</div>
      <SearchBar @onSearch="search" style="text-align:right"></SearchBar>
    </div>


    <div class="topic-box topic-news-list" v-loading="resLoading" v-if="resList2 && resList2.list.length > 0">

      <div class="topic-news">

        <div class="topic-list">
          <template v-for="(item, index) in resList.list2" :key="item.id">
            <div class="topic-item" v-if="index < 5">
              <a href="javascript:void(0)" @click="gotoRes(item.id, item.catalogType)">{{ item.cname }}</a>
              <span class="topic-date">{{ item.createTime }}</span>
            </div>
          </template>
        </div>
      </div>

      <div class="topic-news">

        <div class="topic-list" style="margin-top:22px;">
          <template v-for="(item, index) in resList2.list" :key="item.id">
            <div class="topic-item" v-if="index >= 5">
              <a href="javascript:void(0)" @click="gotoRes(item.id,item.catalogType)">{{ item.cname }}</a>
              <span class="topic-date">{{ item.createTime }}</span>
            </div>
          </template>
        </div>
      </div>
    </div>


    <div v-loading="resLoading" v-if="!(resList && resList.list.length > 0)">

      <div style="text-align: center;padding-top: 40px;color: rgba(106,101,101,0.3)">暂无资源</div>
    </div>

    <div class="pager-list">
      <Pager :totalPage="totalPage" :currentPage="currentPage" @pageChange="pageChange"></Pager>
    </div>


    <div class="topic-news-list-title">
      <div class="topic-title">相关附件</div>
    </div>

    <div class="topic-box topic-news-list" v-loading="resLoading2" v-if="attachList && attachList.list.length > 0">

      <div class="topic-news">

        <div class="topic-list">
          <template v-for="(item, index) in attachList.list" :key="item.id">
            <div class="topic-item" v-if="index < 5">
              <a :href="downFile(item)">{{ item.attachName }}</a>
            </div>
          </template>
        </div>
      </div>

      <div class="topic-news">

        <div class="topic-list" style="margin-top:22px;">
          <template v-for="(item, index) in attachList.list" :key="item.id">
            <div class="topic-item" v-if="index >= 5">
              <a :href="downFile(item)">{{ item.attachName }}</a>
            </div>
          </template>
        </div>
      </div>
    </div>


    <div v-loading="resLoading2" v-if="!(attachList && attachList.list.length > 0)">

      <div style="text-align: center;padding-top: 40px;color: rgba(106,101,101,0.3)">暂无相关附件</div>
    </div>

    <div class="pager-list">
      <Pager :totalPage="totalPageAttach" :currentPage="currentPageAttach" @pageChange="pageChangeAttach"></Pager>
    </div>


  </div>
</template>

<script>
import Bread from "../components/common/Bread"
import Banner from "../components/common/Banner"
import Pager from "../components/common/Pager"
import SearchBar from "../components/common/SearchBar"
import {ref, onMounted, getCurrentInstance} from 'vue'
import {useRoute} from 'vue-router'

export default {
  name: "TopicDetail",
  data() {
    return {
      breads: [{
        text: '首页',
        path: '/index',
        name: 'Index'
      }, {
        text: '专题服务',
        name: 'Topic',
        path: '/topic'
      }, {
        text: '专题详情', link: ''
      }
      ]
    }
  },
  components: {Bread, Banner, Pager, SearchBar},
  setup() {
    const {proxy} = getCurrentInstance();
    const route = useRoute();

    const topicId = route.query.id;
    const loading = ref(false)
    const topic = ref({})
    const banner = ref([]);
    const searchKey = ref('');

    const platformList = ref([]);
    const attachList = ref({total: 1, page: 1, list: []});
    const resList = ref({total: 1, page: 1, list: []});
    const resList2 = ref({total: 1, page: 1, list: []});

    const currentPage = ref(1), totalPage = ref(1), resLoading = ref(false);
    const currentPage2 = ref(1), totalPage2 = ref(1), resLoading2 = ref(false);
    const currentPageAttach = ref(1), totalPageAttach = ref(1), resLoadingAttach = ref(false);

    function getTopicDetail() {
      loading.value = true
      const url = '/gdsti2/web/a/web/specialInfo';
      proxy.$http.post(url, {id: topicId}).then(res => {
        loading.value = false

        let imageList = [];
        if (res.data.state == 0) {

          const imgUrl = proxy.$getImage(res.data.result.img);
          imageList.push({src: imgUrl})
          topic.value = res.data.result;
        }
        banner.value = imageList;

        proxy.$nextTick(() => {
          if (document.getElementsByClassName("showBox")[0].scrollHeight > 120) {
            showMore.value = true;
          } else {
            showMore.value = false;
          }
        })
      }).catch(err => {
        loading.value = false
      })
    }


    function getResList() {  //相关成果（资源1）
      resLoading.value = true;
      const url = '/gdsti2/web/a/web/specialResList';
      proxy.$http.post(url, {
        specialId: topicId,
        specialResType: 1,
        limit: 10,
        offset: (currentPage.value - 1) * 10,
        searchVal: searchKey.value
      }).then(res => {
        resLoading.value = false;
        if (res.data.state == 0) {
          resList.value = res.data.result;
          totalPage.value = res.data.result.page;
        }
      }).catch(err => {
        resLoading.value = false;
      })
    }

    function getResList2() {   //相关资源（资源2）
      resLoading2.value = true;
      const url = '/gdsti2/web/a/web/specialResList';
      proxy.$http.post(url, {
        specialId: topicId,
        specialResType: 2,
        limit: 10,
        offset: (currentPage2.value - 1) * 10
      }).then(res => {
        resLoading2.value = false;
        if (res.data.state == 0) {
          resList2.value = res.data.result;
          totalPage2.value = res.data.result.page;
        }
      }).catch(err => {
        resLoading2.value = false;
      })
    }


    function getPlatformList() {    //获取相关平台信息
      const url = '/gdsti2/web/a/web/specialPlatformList';
      loading.value = true;
      proxy.$http.post(url, {limit: 10, offset: 0, specialId: topicId}).then(res => {
        loading.value = false;
        if (res.data.state == 0) {
          const result = res.data.result;
          platformList.value = result.list;

        }
      }).catch(err => {
        loading.value = false;
      })
    }

    function getAttachList() {    //获取相关附件信息
      const url = '/gdsti2/web/a/web/specialAttachList';
      resLoadingAttach.value = true;
      proxy.$http.post(url, {limit: 10, offset: 0, specialId: topicId, limit: 10, offset: (currentPageAttach.value - 1) * 10}).then(res => {
        resLoadingAttach.value = false;
        if (res.data.state == 0) {
          attachList.value = res.data.result;

          totalPageAttach.value = res.data.result.page;
        }
      }).catch(err => {
        resLoadingAttach.value = false;
      })
    }

    function pageChange(index) {
      currentPage.value = index;
      getResList();
    }

    function pageChange2(index) {
      currentPage2.value = index;
      getResList2();
    }

    function pageChangeAttach(index) {
      currentPageAttach.value = index;
      getAttachList();
    }

    onMounted(() => {
      getTopicDetail();
      getResList();
      getResList2();
      getPlatformList();
      getAttachList();
    })

    const showBox = ref(false);
    const showMore = ref(false);


    return {
      showBox,
      showMore,
      topic,
      searchKey,
      loading,
      banner,
      currentPage,
      totalPage,
      resLoading,
      resList,
      getResList,
      pageChange,
      currentPage2,
      totalPage2,
      resLoading2,
      resList2,
      getResList2,
      pageChange2,
      currentPageAttach,
      totalPageAttach,
      resLoadingAttach,
      attachList,
      getAttachList,
      pageChangeAttach,
      platformList,
      getPlatformList,
    }
  },
  methods: {
    search: function (key) {
      this.searchKey = key;
      this.getResList();
    },
    gotoRes(id, catalogType) {
      // this.$router.push({name: 'Instrument', path:'/instrument', query: {id: id,parentId:parentId}});

      // let routeData = this.$router.resolve({ path: '/instrument', query: {id: id,parentId:parentId} });
      // window.open(routeData.href, '_blank');

      window.open('/instrument?id=' + id + '&catalogType=' + catalogType);

    },
    gotoOrgan(id) {
      // this.$router.push({name: 'Organ', path: '/organ', query: {id: id}})
      let routeData = this.$router.resolve({path: '/organ', query: {id: id}});
      window.open(routeData.href, '_blank');
    },
    downFile(item) {
      return "/base/c/download/file?attachId=" + item.attachCode;
    },


  }
}
</script>

<style lang="less" scoped>
.pager-list {
  margin-top: 50px;
  margin-bottom: 100px;
}

.topic-box {
  display: flex;
  justify-content: space-between;
}

.rela-organ, .rela-result {
  width: 48%;
}

.topic-news {
  width: 48%;
}

.showBox {
  height: 120px;
  overflow: hidden;
  display: -webkit-box;
  text-overflow: ellipsis;
  -webkit-line-clamp: 4;
  word-break: break-all;
  -webkit-box-orient: vertical;
}

.topic-title {
  font-family: SourceHanSansCN-Medium;
  font-size: 20px;
  line-height: 25px;
  border-left: 1px solid #007EEC;
  padding-left: 3px;
  color: rgba(0, 0, 0, 0.85);
  letter-spacing: 0;
  text-align: justify;

}

.topic-news-list-title {
  display: flex;
  justify-content: space-between;
  margin-top: 40px;
}

.topic-list {
  clear: right;
  margin-top: 20px;
  font-family: SourceHanSansCN-Regular;
  font-size: 18px;
  color: rgba(0, 0, 0, 0.85);


  .topic-item {
    margin-bottom: 19px;
    display: flex;
    justify-content: space-between;

    a {
      flex: 1;
      margin-right: 29px;
    }
  }

}

.topic-head {
  padding-bottom: 24px;

  .topic-body {
    width: 691px;

    .topic-name {
      font-family: SourceHanSansCN-Medium;
      font-size: 30px;
      color: rgba(0, 0, 0, 0.85);
    }

    .topic-dept {
      font-family: SourceHanSansCN-Regular;
      font-size: 16px;
      color: #007EEC;
      margin-top: 13px;
    }

    .topic-desc {
      font-family: SourceHanSansCN-Regular;
      font-size: 16px;
      color: rgba(0, 0, 0, 0.85);
      margin-top: 13px;
      line-height: 30px;
    }
  }
}
</style>

